// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { VERSION } from "./version";

export const environment = {
  production: false,
  appVersion: VERSION.version + "." + VERSION.raw + "-stage",
  apiUri: 'https://stagingapi.mhiapps.com',
  appUri: 'https://stagingweb.mhiapps.com',
  loginUri: 'https://staginglogin.mhiapps.com',
  ssoLoginUri: 'https://mckesson-uat.okta.com/app/mckessoncorporationuat_340barchitectuat_1/exk1jormgmu1juTRw0h8/sso/saml',
  apiKey: "493d7b0da57148b08ad8f1cddca2ab62",
  UAMUserApiEndpoint: "/user",
  b2c: {
    RedirectUrl: "https://stagingweb.mhiapps.com/"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
